import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../components/ExternalLink';
import LeadParagraph from '../../components/LeadParagraph';
import InternalLink from '../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "resources",
      "style": {
        "position": "relative"
      }
    }}>{`Resources`}<a parentName="h1" {...{
        "href": "#resources",
        "aria-label": "resources permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
    This page lists different kinds of resources ranging from the City of Helsinki materials to different kinds of tools to make design and development easier.
    </LeadParagraph>
    <h2 {...{
      "id": "downloads",
      "style": {
        "position": "relative"
      }
    }}>{`Downloads`}<a parentName="h2" {...{
        "href": "#downloads",
        "aria-label": "downloads permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/releases" mdxType="ExternalLink">Design kit (Sketch libraries)</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://brand.hel.fi/en/logo/" mdxType="ExternalLink">Helsinki logos</ExternalLink>
      </li>
      <li parentName="ul">{`Helsinki Grotesk font, see `}<InternalLink href="/foundation/design-tokens/typography" mdxType="InternalLink">{`Typography.`}</InternalLink></li>
    </ul>
    <h2 {...{
      "id": "links--guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Links & guidelines`}<a parentName="h2" {...{
        "href": "#links--guidelines",
        "aria-label": "links  guidelines permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://brand.hel.fi/en/" mdxType="ExternalLink">Helsinki Visual Identity guidelines</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.hel.fi/static/liitteet/kanslia/TPR/opas_saavutettavaan_sisaltoon_EN.pdf" mdxType="ExternalLink">Helsinki content accessibility guidelines</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://saavutettavuusmalli.hel.fi" mdxType="ExternalLink"> Helsinki Accessibility Model (in Finnish mostly) </ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://pelikirja.hel.fi" mdxType="ExternalLink"> Helsinki Digital Service Playbook (Pelikirja) (in Finnish) </ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://dev.hel.fi" mdxType="ExternalLink"> Helsinki Developer guidelines </ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://hds.hel.fi/v1" openInNewTab mdxType="ExternalLink"> HDS V1 documentation </ExternalLink>
      </li>
    </ul>
    <h2 {...{
      "id": "tools",
      "style": {
        "position": "relative"
      }
    }}>{`Tools`}<a parentName="h2" {...{
        "href": "#tools",
        "aria-label": "tools permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://www.getstark.co/" mdxType="ExternalLink">Stark Sketch plugin</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://cluse.cc/" mdxType="ExternalLink">Cluse Sketch plugin</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://webaim.org/resources/contrastchecker/" mdxType="ExternalLink">WebAIM Contrast checker</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://www.aremycolorsaccessible.com/" mdxType="ExternalLink">Are My Colours Accessible?</ExternalLink>
      </li>
    </ul>
    <h2 {...{
      "id": "hds-repositories",
      "style": {
        "position": "relative"
      }
    }}>{`HDS repositories`}<a parentName="h2" {...{
        "href": "#hds-repositories",
        "aria-label": "hds repositories permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <ul>
      <li parentName="ul">
        <ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/core" mdxType="ExternalLink">HDS Core</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/react" mdxType="ExternalLink">HDS React</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/packages/design-tokens" mdxType="ExternalLink">HDS Design tokens</ExternalLink>
      </li>
      <li parentName="ul">
        <ExternalLink href="https://github.com/City-of-Helsinki/helsinki-design-system/tree/master/site" mdxType="ExternalLink">HDS Documentation site</ExternalLink>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      